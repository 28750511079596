<template>
  <section class="about">
    <v-row>
      <v-col
        offset-xl="1"
        offset-lg="1"
        offset-md="2"
        offset-sm="1"
        offset="1"
        cols="10"
        xl="5"
        lg="5"
        md="8"
        sm="10"
        class="d-flex justify-center align-center"
      >
        <div class="about-text">
          <h2 class="about-text_title">
            {{ title }}
          </h2>
          <div class="about-text_content">
            {{ content }}
            <br><br>
            {{ paragraph }}
          </div>
        </div>
      </v-col>
      <v-col
        offset-xl="0"
        offset-lg="0"
        offset-md="2"
        offset-sm="1"
        offset="1"
        cols="10"
        xl="5"
        lg="5"
        md="8"
        sm="10"
        class="d-flex align-center justify-center"
      >
        <div class="about-bg">
          <slot />
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    paragraph: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.about {
    display: flex;
    padding-bottom: 140px;
}

.about-text_title {
    font-size: 42px;
    line-height: 44px;
    color: #372E3B;
}

.about-text_content {
    width: 700px;
    margin-top: 45px;
    font-weight: 400;
    font-size: 20px;
    color: #372E3B;
}

.about-bg img{
    width: 100%;
}

@media screen and (max-width: 1728px) {
  .about-text_content {
    width: 550px;
  }
}

@media screen and (max-width: 1434px) {
  .about-text_content {
    width: 500px;
  }
}

@media screen and (max-width: 1260px) {
  .about-text_content {
    width: 700px;
    text-align: center;
  }
  .about-text_title {
    width: 700px;
    text-align: center;
}
}

@media screen and (max-width: 960px) {
  .about-text_content {
    width: 550px;
    text-align: center;
  }
  .about-text_title {
    width: 550px;
    text-align: center;
}
}

@media screen and (max-width: 600px) {
  .about-text_content {
    width: 400px;
    text-align: center;
  }
  .about-text_title {
    width: 400px;
    text-align: center;
}
}

@media screen and (max-width: 600px) {
  .about-text_content {
    width: 300px;
    text-align: center;
  }
  .about-text_title {
    width: 300px;
    text-align: center;
}
}
</style>
