<template>
  <v-app>
    <the-header />
    <v-main class="pa-0">
      <the-promo @scrollToElement="scrollToElement" />
      <basic-form />
      <upper-plates />
      <about-general ref="scrollToMe" />
      <about-business />
      <step-by-step />
      <partner-tutore />
      <down-plates />
      <be-partner />
      <basic-form
        mode="colored-bg"
        text-color="white-text"
        label-color="white-label"
      />
    </v-main>
  </v-app>
</template>

<script>
import TheHeader from './components/layouts/TheHeader.vue';
import ThePromo from './components/layouts/ThePromo.vue';
import BasicForm from './components/ui/BasicForm.vue';
import UpperPlates from './components/plates/UpperPlates.vue';
import DownPlates from './components/plates/DownPlates.vue';
import AboutGeneral from './components/about/AboutGeneral.vue';
import AboutBusiness from './components/about/AboutBusiness.vue';
import StepByStep from './components/partner/StepByStep.vue';
import PartnerTutore from './components/partner/PartnerTutore.vue';
import BePartner from './components/partner/BePartner.vue';

export default {
  components: {
    TheHeader,
    ThePromo,
    BasicForm,
    UpperPlates,
    DownPlates,
    AboutGeneral,
    AboutBusiness,
    StepByStep,
    PartnerTutore,
    BePartner,
  },
  methods: {
    scrollToElement() {
      const el = this.$refs.scrollToMe.$el;
      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },

  },

};
</script>

<style>
* {
  font-family: 'Poppins', sans-serif;
}
</style>
