<template>
  <v-form
    ref="form"
    :class="mode"
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      bottom
    >
      Dane zostały przesłane poprawnie!
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-row class="d-flex align-center justify-center">
      <v-col
        xl="3"
        lg="4"
        md="5"
        sm="12"
        class="pb-0"
      >
        <v-text-field
          v-model.trim="name"
          :class="labelColor"
          class="test"
          color="#707070"
          placeholder=" "
          height="60"
          outlined
          persistent-placeholder
          label="Imię"
          :rules="nameRule"
        />
      </v-col>
      <v-col
        xl="3"
        lg="4"
        md="5"
        sm="12"
        class="pb-0"
      >
        <v-text-field
          v-model.trim="surname"
          :class="labelColor"
          class="test"
          color="#707070"
          placeholder=" "
          height="60"
          outlined
          persistent-placeholder
          label="Nazwisko"
          :rules="surnameRule"
        />
      </v-col>
      <v-col
        xl="3"
        lg="4"
        md="5"
        sm="12"
        class="pb-0"
      >
        <v-text-field
          v-model.trim="phone"
          :class="labelColor"
          class="test"
          type="number"
          color="#707070"
          placeholder=" "
          height="60"
          outlined
          persistent-placeholder
          label="Numer telefonu"
          :rules="phoneRule"
        />
      </v-col>
      <v-col
        xl="3"
        lg="4"
        md="5"
        sm="12"
        class="d-flex align-center justify-center"
      >
        <button
          class="submit-btn"
          @click.prevent="submit"
        >
          Wyślij
        </button>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        offset-xl="0"
        offset-lg="0"
        offset-md="1"
        xl="12"
        lg="12"
        md="10"
        sm="12"
      >
        <input
          id="check"
          v-model="checkbox"
          class="checkboxInput"
          type="checkbox"
        >
        <label
          v-if="checkboxIsValid"
          class="checkboxLabel"
          :class="textColor"
          for="check"
        >
          Potwierdzam zapoznanie się z klauzulą informacyjną dotyczącą przetwarzania moich danych osobowych przez Music & More Sp. z o.o. oraz wyrażam zgodę na kontakt telefoniczny w celach biznesowych.
        </label>
        <label
          v-else
          for="check"
          class="invalid-checkbox"
        >
          Potwierdzam zapoznanie się z klauzulą informacyjną dotyczącą przetwarzania moich danych osobowych przez Music & More Sp. z o.o. oraz wyrażam zgodę na kontakt telefoniczny w celach biznesowych.
        </label>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

export default {
  props: {
    mode: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '',
    },
    labelColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      name: '',
      surname: '',
      phone: '',
      checkbox: null,
      checkboxIsValid: true,
      snackbar: false,
      nameRule: [
        (v) => v.trim().length > 0 || 'Wpisz swoje imię',
      ],
      surnameRule: [
        (v) => v.trim().length > 0 || 'Wpisz swoje nazwisko',
      ],
      phoneRule: [
        (v) => v.length > 0 || 'Wpisz swój numer',
      ],
    };
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate() && this.checkbox) {
        const url = 'https://docs.google.com/forms/d/e/1FAIpQLSeOQ2fwWDiv_4dlEDudwOXKO2FBAeOnOJhEkknmyRaclDTUcg/formResponse';
        const body = {
          'entry.1633143099': this.name,
          'entry.50952525': this.surname,
          'entry.1287807483': this.phone,
        };
        console.log(body);
        try {
          await this.postData(url, body);
        } catch (error) {
          console.log(error);
        }

        this.$refs.form.reset();

        this.checkbox = null;
        this.snackbar = true;
        this.checkboxIsValid = true;
      } else {
        this.checkboxIsValid = false;
      }
    },
    postData(url, data) {
      return fetch(url, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(data),
      });
    },
  },

};
</script>

<style scoped>
/* .test >>> .v-input--is-focused .v-input__slot {
} */

.test >>> input {
    font-size: 25px;
}

.test >>> .v-input__slot > fieldset {
  border-radius: 15px;
  background-color: #fff !important;
}

.test >>> .v-input__control {
  display: flex;
  justify-content: center;
  align-items: center;
}

.test >>> .v-input__slot {
  margin-top: 24px;
  /* background-color: #fff; */
  width: 328px;
}

.test >>> .v-input__slot > .v-text-field__slot > label {
  padding: 5px;
  height: 35px;
  font-weight: 600;
  left: -10px !important;
  top: -5px;
  font-size: 35px;
  color: #372E3B
}

.white-label >>> .v-input__slot > .v-text-field__slot > label {
  color: #fff;
}

.test >>> .v-input__slot > fieldset > legend {
  width: 0 !important;
}

form {
    min-height: 342px;
    padding: 84px 220px 84px 208px;
}

.invalid-checkbox {
    display: block;
    margin-top: -22px;
    margin-left: 20px;
    font-size: 12px;
    font-weight: 400;
    color: red;
}

.submit-btn {
    width: 328px;
    height: 60px;
    margin-top: 5px;
    background: #E72F84 0% 0% no-repeat padding-box;
    border: 1px solid #E72F84;
    border-radius: 15px;
    color: #FCFCFC;
    font-size: 31px;
    font-weight: bold;
}

.submit-btn:hover {
    background: #E72F8499 0% 0% no-repeat padding-box;
    border: 1px solid #E72F84;
    opacity: 0.6;
}

.checkboxLabel {
    display: block;
    margin-top: -22px;
    margin-left: 20px;
    font-size: 12px;
    color: #372E3B;
    font-weight: 400;
}

.colored-bg {
    background-color: #36348A;
}

.white-text {
    color: #FCFCFC;
}

@media screen and (max-width: 1450px) {
form {
   padding: 84px 100px 84px 100px;
  }
}

@media screen and (max-width: 450px) {
form {
   padding: 84px 25px 84px 25px;
}

.white-text {
    color: #FCFCFC;
}
}
</style>
